import {
	UserIcon,
	CogIcon,
	HeartIcon,
	ArrowRightStartOnRectangleIcon,
	ChatBubbleOvalLeftEllipsisIcon,
	BuildingStorefrontIcon,
	EnvelopeIcon,
	NewspaperIcon,
	SparklesIcon,
	Square3Stack3DIcon,
} from '@heroicons/react/24/outline'
import { Form, useLocation } from '@remix-run/react'
import { type TFunction } from 'i18next'
import * as React from 'react'

import { useTranslation } from 'react-i18next'
import {
	LocaleLink,
	type InternalRoutes,
} from '#app/components/locale-link.tsx'
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuList,
	NavigationMenuTrigger,
} from '#app/components/ui/navigation-menu.tsx'
import { useCurrentLanguage } from '#app/hooks/use-current-language.tsx'
import { useOptionalUser } from '#app/utils/user.ts'

type MenuItem = {
	name: string
	description: string
	redirectId: InternalRoutes
	icon: React.ForwardRefExoticComponent<
		React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
	>
}

interface CallToAction {
	name: string
	searchParams?: string
	redirectId: InternalRoutes
	icon: React.ForwardRefExoticComponent<
		React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
	>
}

const menuItems = (t: TFunction, user: any): MenuItem[] => [
	{
		name: t('menu.chat'),
		description: t('menu.chatSubtitle'),
		redirectId: 'chat',
		icon: ChatBubbleOvalLeftEllipsisIcon,
	},
	{
		name: user ? t('menu.myProfile') : t('menu.pricing'),
		description: user ? t('menu.myProfileSubtitle') : t('menu.pricingSubtitle'),
		redirectId: user ? 'profileOverview' : 'pricing',
		icon: Square3Stack3DIcon,
	},
	{
		name: t('menu.services'),
		description: t('menu.servicesSubtitle'),
		redirectId: 'services',
		icon: BuildingStorefrontIcon,
	},
	{
		name: t('menu.contact'),
		description: t('menu.contactSubtitle'),
		redirectId: 'contactWiser',
		icon: EnvelopeIcon,
	},
	{
		name: t('menu.about'),
		description: t('menu.aboutSubtitle'),
		redirectId: 'aboutWiser',
		icon: SparklesIcon,
	},
	{
		name: t('menu.blog'),
		description: t('menu.blogSubtitle'),
		redirectId: 'blogs',
		icon: NewspaperIcon,
	},
]

const callsToActionGuest = (
	t: TFunction,
	currentLanguage: string,
): CallToAction[] => [
	{
		name: t('menu.login'),
		redirectId: 'login',
		searchParams: `redirectTo=/${currentLanguage}/chat`,
		icon: UserIcon,
	},
	{
		name: t('menu.register'),
		redirectId: 'signup',
		searchParams: `redirectTo=/${currentLanguage}/intake`,
		icon: HeartIcon,
	},
]

const callsToAction = (t: TFunction): CallToAction[] => [
	{
		name: t('menu.settings'),
		redirectId: 'settings',
		icon: CogIcon,
	},
	{
		name: t('menu.logout'),
		redirectId: 'homeNew',
		icon: ArrowRightStartOnRectangleIcon,
	},
]
export function MainMenu() {
	const user = useOptionalUser()
	const currentLanguage = useCurrentLanguage()
	const location = useLocation()
	const { t } = useTranslation(['common'])

	const currentCallToActions = user
		? callsToAction(t)
		: callsToActionGuest(t, currentLanguage)

	return (
		<NavigationMenu className="z-50">
			<NavigationMenuList>
				<NavigationMenuItem className="relative">
					<NavigationMenuTrigger className="inline-flex items-center">
						{t('menu.menu')}
					</NavigationMenuTrigger>
					<NavigationMenuContent className="border-none bg-background">
						<div className="min-w-[20rem] max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 md:w-screen lg:max-w-3xl">
							<div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
								{menuItems(t, user).map(item => (
									<div
										key={item.name}
										className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-accent"
									>
										<div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-accent">
											<item.icon
												className="h-6 w-6 text-gray-600 group-hover:text-primary-400"
												aria-hidden="true"
											/>
										</div>
										<div>
											<LocaleLink
												lang={currentLanguage}
												route={item.redirectId}
												className="font-semibold text-primary-400"
											>
												{item.name}
												<span className="absolute inset-0" />
											</LocaleLink>
											<p className="mt-1 text-gray-600">{item.description}</p>
										</div>
									</div>
								))}
							</div>

							<div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-background">
								{currentCallToActions.map(item => {
									if (item.name === t('menu.logout')) {
										return (
											<Form
												action={
													location.pathname.includes('/chat/')
														? '/logout'
														: `/logout?redirectTo=${encodeURIComponent(
																location.pathname,
															)}`
												}
												method="POST"
												className="w-full"
												key={item.name}
											>
												<button
													type="submit"
													className="flex w-full items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-accent"
												>
													{item.name}
												</button>
											</Form>
										)
									}

									return (
										<LocaleLink
											lang={currentLanguage}
											key={item.name}
											searchParams={item.searchParams}
											route={item.redirectId}
											className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-accent"
										>
											<item.icon
												className="h-5 w-5 flex-none text-gray-400"
												aria-hidden="true"
											/>
											{item.name}
										</LocaleLink>
									)
								})}
							</div>
						</div>
					</NavigationMenuContent>
				</NavigationMenuItem>
			</NavigationMenuList>
		</NavigationMenu>
	)
}
